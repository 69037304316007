import { createReducer, on } from '@ngrx/store';
import { CancellationRole } from '@libs/entity-lib';
import * as fromCancellationRole from './cancellation-role.actions';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';


export interface State extends EntityState<CancellationRole> {
  // additional entities state properties
  loading: boolean;
  error: any | null;
}

export function selectCancellationRoleId(a: CancellationRole): number {
  //In this case this would be optional since primary key is id
  return a.id ?? -1;
}

export const adapter: EntityAdapter<CancellationRole> = createEntityAdapter<CancellationRole>({
  selectId: selectCancellationRoleId,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  error: null
});

export const reducer = createReducer(
  initialState,
  on(fromCancellationRole.fetchCancellationRolesSuccefully, (state, { cancellationRoles }) => {
    return adapter.setAll(cancellationRoles, { ...state, loading: false, error: null });
  }),
  on(fromCancellationRole.registerCancellationRolesSuccefully, (state, { cancellationRole }) => {
    return adapter.addOne(cancellationRole,
    { 
      ...state, 
      loading: false, 
      error: null 
    });
  }),
  on(fromCancellationRole.deleteCancellationRolesSuccefully, (state, { id }) => {
    return adapter.removeOne(id, { ...state, loading: false, error: null });
  }),
  on(fromCancellationRole.fetchCancellationRolesFailed, (state, { error }) => ({ ...state, error })),
  on(fromCancellationRole.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  }))
);

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

// select the array of court ids
export const selectCancellationRolesIds = selectIds;

// select the dictionary of court entities
export const selectCancellationRolesEntities = selectEntities;

// select the array of courts
export const selectAllCancellationRoles = selectAll;

// select the total court count
export const selectCancellationRolesTotal = selectTotal;
